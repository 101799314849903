//#region header */
/**************************************************************************************************
//
//  Description: URL data about the api calls we need to make
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   02.07.21 Sean Flook         WI39??? Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */
//import React from "react";
import { env } from "./../env"

var currentConfig = {
    securityApi: ""
};

const getConfigInfo = () => {
    if (
        !currentConfig ||
        !currentConfig.securityApi ||
        currentConfig.securityApi.length === 0
    ) {

        currentConfig = {
            securityApi: env.REACT_APP_SECURITY_API,
            version: env.REACT_APP_SECURITY_VERSION
        };
    }

    //console.log("[SF] getConfigInfo", currentConfig);
};

/*
 * 
 * Security Urls 
 * 
 */

const GetSecurityApiSite = (urlController) => {
    getConfigInfo();
    const SecurityApiUrl = `${currentConfig.securityApi}/api/v${currentConfig.version}/${urlController}`;
    return SecurityApiUrl;
}

const GetError = async (res) => {
    const responseText = await res.text().then((err) => { return (err ? err : ""); });
    try {
        const jsonText = JSON.parse(responseText);
        return JSON.stringify(jsonText.errors ?? jsonText);
    }
    catch (err) {
        return responseText;
    }
};

export async function FetchWithError(urlData) {
    if (urlData) {
        const retVal = await fetch(`${urlData.url}`, {
            cache: "no-cache",
            method: urlData.method,
            mode: urlData.mode,
            crossDomain: urlData.crossDomain,
            headers: urlData.headers,
            body: (urlData.body) ? JSON.stringify(urlData.body) : null,
        })
            .then((res) => (res.ok ? res : Promise.reject(res)))
            .then((res) => (urlData.jsonResult ? res.json() : res.text()))
            .then((result) => {
                return result;
            })
            .catch(async (error) => {

                const errText = await GetError(error);

                switch (error.status) {
                    case 400:
                        return (errText.length > 0) ? `An error occurred : ${errText}` : `An error occurred while process this request`;
                    case 401:
                        return "Your credentials have expired, please login again.";

                    case 403:
                        return "You do not have the correct admin rights to change this user.";

                    default:
                        return `error (${error.status}) has occurred, please report to support.`;
                }
            });

        return retVal;
    }
    else {
        return "No urlData provided for fetch";
    }
}


export async function FetchData(urlData, errorHandler,) {
    if (urlData) {
        const retVal = await fetch(`${urlData.url}`, {
            cache: "no-cache",
            method: urlData.method,
            mode: urlData.mode,
            crossDomain: urlData.crossDomain,
            headers: urlData.headers,
            body: (urlData.body) ? JSON.stringify(urlData.body) : null,
        })
            .then((res) => (res.ok ? res : Promise.reject(res)))
            .then((res) => (urlData.jsonResult ? res.json() : res.text()))
            .then((result) => {
                return result;
            })
            .catch(async (error) => {

                const errText = await GetError(error);
                //console.log("fetchdata error ",errText);

                switch (error.status) {
                    case 400:
                        errorHandler && errorHandler(errText.length > 0 ? `An error occurred : ${errText}` : `An error occurred while process this request`);
                        break;

                    case 401:
                        errorHandler && errorHandler("Your credentials have expired, please login again.");
                        break;

                    case 403:
                        errorHandler && errorHandler("You do not have the correct admin rights to change this user.");
                        break;

                    default:
                        errorHandler && errorHandler(`error (${error.status}) has occurred, please report to support.`);
                        break;
                }
                return error;
            });

        return retVal;
    }
    else {
        errorHandler("No urlData provided for fetch");
        return null;
    }
}

export async function LoginUser(auditname, password, errorHandler) {
    const apiUrl = GetSecurityApiSite("Authority/Login");
    //console.log("login user ",username, password, apiUrl);

    const loginDetails = {
        auditname: auditname,
        password: password,
    };

    const loginRes = await fetch(apiUrl, {
        cache: "no-cache",
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        crossDomain: true,
        body: JSON.stringify(loginDetails),
    })
        .then((res) => (res.ok ? res : Promise.reject(res)))
        .then((res) => res.json())
        .then((result) => {
            //console.log("login result",result);
            return result;
        })
        .catch((error) => {
            //console.log("login error",error);
            switch (error.status) {
                case 400:
                    errorHandler && errorHandler("You need to enter a valid username and password, please try again.");
                    break;

                case 401:
                    errorHandler && errorHandler("Unknown username or password, please try again.");
                    break;

                default:
                    errorHandler && errorHandler("An unknown error occurred, please report to support.");
                    break;
            }
            return null;
        });

    return loginRes;
}

export async function AuthenticateUser(authorizeId, authorizeCode, errorHandler) {
    const apiUrl = GetSecurityApiSite("Authority/authenticate");
    //console.log("authenticate user ",authorizeId, authorizeCode, apiUrl);

    const loginRes = await fetch(`${apiUrl}/${authorizeId}/${authorizeCode}`, {
        cache: "no-cache",
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        crossDomain: true,
    })
        .then((res) => (res.ok ? res : Promise.reject(res)))
        .then((res) => res.json())
        .then((result) => {
            //console.log("login result",result);
            return result;
        })
        .catch((error) => {
            //console.log("login error",error);
            switch (error.status) {
                case 400:
                    errorHandler && errorHandler("An error has occurred during Authentication, please try again.");
                    break;

                case 401:
                    errorHandler && errorHandler("Cannot verify authenticate code, please try again.");
                    break;

                default:
                    errorHandler && errorHandler("An unknown error occurred, please report to support.");
                    break;
            }
            return null;
        });

    return loginRes;
}


export async function WhoAmI(userToken, errorHandler) {

    if (userToken) {
        const apiUrl = await GetSecurityApiSite("Authority/WhoAmI");
        const urlData = {
            url: apiUrl,
            method: "GET",
            mode: "cors",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + userToken,
            },
            body: null,
            jsonResult: true
        }

        const fetchResponse = await FetchData(urlData, errorHandler)
        return fetchResponse;
    }
    else {
        errorHandler && errorHandler("Token is invalid");
        return null;
    }
}
