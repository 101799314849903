import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../context/userContext";
import { AuthenticateUser } from "../configuration/AAConfig_Security";
import {
    Button,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import CloseIcon from "@mui/icons-material/Close";
import { FormRowStyle, FormInputStyle } from "../utils/AAStyles";
import { useTheme } from '@mui/material/styles';

AuthenticateDialog.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
};

function AuthenticateDialog({ title, message }) {
    const theme = useTheme();

    const { authenticateId, updateAuthenticateId, updateShowAuthenticate, updateLoginDetails, showAuthenticate, updateUserError, userError, updateUseAuthenticate } = useContext(UserContext);

    const [authenticateCode, setAuthenticationCode] = useState("");
    const [authenticateError, setAuthenticateError] = useState(userError);

    const handleAuthenticateCodeChangeEvent = (event) => {
        setAuthenticationCode(event.target.value);
    };

    const IsCodeValid = () => {
        const bAuthorizeIdEmpty = !authenticateId || authenticateId.length === 0;
        const bAuthentication = !authenticateCode || authenticateCode.length === 0;
        return !(bAuthorizeIdEmpty || bAuthentication);
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleAuthenticateClick = async () => {

        //console.log("authorize dialog",authenticateCode, authenticateId);

        if (!IsCodeValid()) {
            updateUserError("Authentication or Authorization is empty.");
            return;
        }

        const loginRes = await AuthenticateUser(authenticateId, authenticateCode, setAuthenticateError)
        updateLoginDetails(loginRes);

        //console.log("login result",loginRes);
        if (!loginRes) {
            setAuthenticateError("Unable to login with the supplied code.");
        }
        else {
            updateShowAuthenticate(false);
        }
    };

    const handleCancelClick = () => {
        setAuthenticateError(null);
        updateAuthenticateId(null);
        updateShowAuthenticate(false)
        //updateShowLogin(false);
    };

    useEffect(() => {
        setAuthenticateError(userError);
    }, [userError])

    useEffect(() => {
        updateUseAuthenticate(true);
    }, []);

    useEffect(() => {
        if (authenticateError && authenticateError.length > 0) {
            if (authenticateCode || authenticateCode.length > 0) setAuthenticationCode("");
            //if(username || username.length > 0) setUsername("");
            //updateShowLogin(true);
        }
    }, [authenticateError]);

    return (
        <Dialog
            open={showAuthenticate}
            aria-labelledby="authenticate-dialog"
            fullWidth
            maxWidth="xs"
            disableEnforceFocus
        >
            <DialogTitle
                id="authenticate-dialog"
                sx={{ color: "#FFFFFF", backgroundColor: "#2A6EBB" }}
            >
                {title ? title : "Authenticate User"}
            </DialogTitle>
            <DialogContent sx={{ marginTop: theme.spacing(1) }}>
                {authenticateError ? (
                    <Typography variant="body1" color="error">
                        {authenticateError}
                    </Typography>
                ) : (
                    <Typography variant="body1">{message}</Typography>
                )}
                <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={FormRowStyle()}
                >
                    <Grid item xs={6}>
                        <Typography
                            id="aa-text-label-Code"
                            variant="body2"
                            color="initial"
                            align="left"
                        >
                            Authentication Code*
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="aa-text-textfield-code"
                            sx={FormInputStyle()}
                            fullWidth
                            required
                            variant="outlined"
                            margin="dense"
                            size="small"
                            value={authenticateCode}
                            onChange={handleAuthenticateCodeChangeEvent}
                            aria-labelledby="aa-text-label-code"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ marginBottom: theme.spacing(1) }}>
                <Button
                    onClick={handleAuthenticateClick}
                    autoFocus
                    variant="contained"
                    sx={{
                        color: "#FFFFFF",
                        backgroundColor: "#2A6EBB",
                        "&:hover": {
                            backgroundColor: "#00AEEF",
                            color: "#FFFFFF",
                        },
                    }}
                    startIcon={<LoginIcon />}
                >
                    Authenticate
                </Button>
                <Button
                    onClick={handleCancelClick}
                    sx={{
                        color: "#2A6EBB",
                        "&:hover": {
                            backgroundColor: "#00AEEF",
                            color: "#FFFFFF",
                        },
                    }}
                    startIcon={<CloseIcon />}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AuthenticateDialog;
