/* #region header */
/**************************************************************************************************
//
//  Description: user Context
//
//  Copyright:    © 2022 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   28.01.22 Sean Flook         WI39??? Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React, { useEffect, useState } from "react";
import { WhoAmI } from "./../configuration/AAConfig_Security";

/* #endregion imports */

const UserContext = React.createContext();
UserContext.displayName = "userContext";

const UserContextProvider = ({ props, children }) => {
    //const { currentUser: currentUser, onUserChange: handleUserChange }
    const tokenStorageName = "curToken";

    const GetLocalStorage = (key) => {
        const val = localStorage.getItem(tokenStorageName);
        return (!val || (val === 'null')) ? null : val;
    }

    const [loginDetails, setLoginDetails] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [userError, setUserError] = useState(null);
    const [useAuthenticate, setUseAuthenticate] = useState(null);
    const [currentToken, setCurrentToken] = useState(GetLocalStorage(tokenStorageName));
    const [authenticateId, setAuthenticateId] = useState(null);
    const [showAuthenticate, setShowAuthenticate] = useState((authenticateId !== null) && !currentToken);
    const [showLogin, setShowLogin] = useState(!currentToken);


    // Local Storage: setting & getting data
    useEffect(() => {
        const storedtokenData = GetLocalStorage(tokenStorageName);

        if (currentToken && (storedtokenData !== currentToken)) {
            localStorage.setItem(tokenStorageName, currentToken)
        }

        if (!currentToken && storedtokenData) {
            setShowLogin(false);
            setCurrentToken(storedtokenData);
        }


        async function GetUser() {

            const userInfo = await WhoAmI(currentToken, setUserError);


            if (userInfo && userInfo.rights) {
                //console.log("UserInfo",userInfo);
                if (userInfo.rights.findIndex((x) => (x === "SuperAdministrator" || x === "OrganisationAdministrator")) < 0) {
                    //console.log("Insufficient Rights");
                    setUserError("You dont have sufficient rights to edit other users");
                }
                else {
                    if (userInfo.active && !userInfo.isDeleted) {
                        setUserError(null);
                        setCurrentUser(userInfo);
                    }
                    else {
                        updateCurrentUser(null);
                        //userContext.onUserChange(null);
                        setUserError(!userInfo.active ?
                            "You are not an active user on this system." :
                            "This user has been deleted.");
                    }
                }
            }
            else {
                setUserError("Unable to get user information.");
            }
        }

        if (currentToken && !currentUser) {
            GetUser();
        }

    }, [currentToken]);


    useEffect(() => {

        if (loginDetails && loginDetails.token) {
            setCurrentToken(loginDetails.token);
        }
        else {
            setCurrentUser(null);
            setCurrentToken(null);
        }
    }, [loginDetails])


    useEffect(() => {
        //console.log("userContext :: currentUser", currentUser);
        setShowLogin(!currentUser)
        if (useAuthenticate)
            setShowAuthenticate((authenticateId !== null) && !currentUser)
    }, [currentUser])

    useEffect(() => {
        if (useAuthenticate) {
            //console.log("userContext ::useeffect authenicateId", authenticateId, showLogin);
            setShowLogin(!(authenticateId !== null));
            setShowAuthenticate(!!authenticateId);
        }
    }, [authenticateId])


    useEffect(() => {
        if (userError && userError.length > 0) {
            setLoginDetails(null);
            localStorage.setItem(tokenStorageName, null);
            setCurrentUser(null);
            if (useAuthenticate) {
                setAuthenticateId(null);
                setShowAuthenticate(false);
            }
            setShowLogin(true);
        }
    }, [userError])


    function logoff(reload) {
        console.log(`loggoff called`);
        setLoginDetails(null);
        localStorage.setItem(tokenStorageName, null);

        if (reload)
            window.location.reload();
    }

    function updateLoginDetails(newLogin) {
        //console.log(`updatelogindetails : `,newLogin);
        setLoginDetails(newLogin);
    }

    function updateCurrentUser(newUser) {
        //console.log(`updatecurrentuser :`,newUser);
        setCurrentUser(newUser);
    }

    function updateUserError(error) {
        //console.log(`updatecurrentuser :`,newUser);
        setUserError(error);
    }

    function updateShowLogin(newshowLogin) {
        //console.log(`show login set : ${newshowLogin} :: ${showLogin}`);
        setShowLogin(newshowLogin);
    }

    function updateAuthenticateId(newAuthenticateId) {
        //console.log(`authenticate Id set : ${newAuthenticateId} :: ${authenticateId}`);
        setAuthenticateId(newAuthenticateId);
    }

    function updateShowAuthenticate(newshowAuthenticate) {
        //console.log(`show authenticate set : ${newshowAuthenticate} :: ${showAuthenticate}`);
        setShowAuthenticate(newshowAuthenticate);
    }

    function updateUseAuthenticate(newUseAuthenticate) {
        //console.log(`show authenticate set : ${newshowAuthenticate} :: ${showAuthenticate}`);
        setUseAuthenticate(newUseAuthenticate);
    }


    return (
        <UserContext.Provider value={{ currentToken, currentUser, useAuthenticate, authenticateId, showLogin, showAuthenticate, userError, logoff, setShowAuthenticate, updateUserError, updateLoginDetails, updateCurrentUser, updateShowLogin, updateAuthenticateId, updateShowAuthenticate, updateUseAuthenticate }}>
            {children}
        </UserContext.Provider>
    )
}

export { UserContextProvider, UserContext }
